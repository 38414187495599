import { ServerInfoConverter } from "../../../core/api-repository/data-source/service-api/converter/server/server-info-converter";
import { Manifest } from "../../../core/api-repository/data-source/service-api/manifest-service-api";
import { AppError } from "../../core/app-error";
import { ErrorKind } from "../../core/error-kind";
import { ServerInfo } from "../../server/server-info";

declare global {
    var serverInfo: ServerInfo; // eslint-disable-line no-var -- Must be ignored because it is a global vairable
}

export function loadServerInfo() {
    return new Promise<ServerInfo>((resolve, reject) => {
        globalThis.serverInfo = null;

        function errorListener() {
            reject(new AppError(ErrorKind.Unknown, this.responseText, ""));
        }

        function successListener() {
            let converter = new ServerInfoConverter();

            try {
                let parsedJson = JSON.parse(this.responseText);

                try {
                    let result = converter.convert(converter.parse(parsedJson));
                    globalThis.serverInfo = result;
                    resolve(result);
                } catch {
                    reject(AppError.parseServerApiError({ error: parsedJson }));
                }
            } catch {
                reject(new AppError(ErrorKind.Unknown, this.responseText, ""))
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.addEventListener("load", successListener);
        xhr.addEventListener("error", errorListener);
        xhr.open("GET", Manifest.Url.Server.serverInfo);
        xhr.send();
    });
}
