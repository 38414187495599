
/**
 */
export class ServerInfoDTO {
        /**
     * Determines if the current instance is running in single tenant mode.
     */
    public IsSingleTenant: boolean;

    /**
     * Current user culture LCID.
     */
    public CurrentCulture: number;

    /**
     * Current user culture IetfLanguageTag.
     */
    public CurrentLocale: string;

    /**
     * Current year.
     */
    public Year: number;

    /**
     * Session timeout in minutes.
     */
    public Timeout: number;

    /**
     * Product version.
     */
    public Version: string;

    /**
     * Supported cultures.
     */
    public SupportedCultures: number[];

    /**
     * Activated features flags.
     */
    public Features: string[];


}