import { FeatureFlag } from "../../core/api-repository/data-source/service-api/model/feature-flag";

export class ServerInfo {

    /**
     * Determines if the current instance is running in single tenant mode.
     */
    public isSingleTenant: boolean;

    /**
     * Current user culture LCID.
     */
    public currentCulture: number;

    /**
     * Current user culture IetfLanguageTag.
     */
    public currentLocale: string;

    /**
     * Current year.
     */
    public year: number;

    /**
     * Session timeout in minutes.
     */
    public timeout: number;

    /**
     * Product version.
     */
    public version: string;

    /**
     * Supported cultures.
     */
    public supportedCultures: number[];

    /**
     * Activated features flags.
     */
    public features: string[];

    /**
     * Check if a feature is enabled with feature flag.
     * @param {FeatureFlag} featureFlag - Feature flag to check.
     * @returns True if feature is enabled, false if the feature is not enabled.
     */
    public featureIsEnabled(featureFlag: FeatureFlag): boolean {
        return this.features.includes(FeatureFlag[featureFlag]);
    }
}