import { ApplicationRef, enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "./environments/environment";

import "@angular/compiler";
import { enableDebugTools } from "@angular/platform-browser";

if (environment.production) {
    enableProdMode();
}

import { loadServerInfo } from "./app/model/angular/hubshare-angular-app/server-info-loader";
new Promise<void>((resolve, reject) => {
    loadServerInfo().then(() => {
        import("src/app/extension/primitive").then(() => {
            import("src/app/model/angular/hubshare-angular-app/hubshare-service").then(() => {
                import("./app/app.module").then((m) => {
                    const bootstrap = () => platformBrowserDynamic().bootstrapModule(m.AppModule);
                    environment.bootstrapModule(bootstrap).then(ref => {
                        enableDebugTools(ref.injector.get(ApplicationRef).components[0]);
                        resolve();
                    }).catch(reject);
                }).catch(reject);
            }).catch(reject);
        }).catch(reject);
    }).catch(reject);
}).catch(err => console.error(err));