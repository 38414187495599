import { ServerInfo } from "../../../../../../model/server/server-info";
import { ServerInfoDTO } from "../../model/server/server-info-dto";
import { ServiceApiConverter } from "../service-api-converter";

export class ServerInfoConverter extends ServiceApiConverter {
    public convert(source: ServerInfoDTO): ServerInfo {
        return ServerInfoConverter.toModel(source);
    }

    public static toDTO(source: ServerInfo): ServerInfoDTO {
        if (source == null)
            return null;

        let result = new ServerInfoDTO();

        result.CurrentCulture = source.currentCulture;
        result.CurrentLocale = source.currentLocale;
        result.Year = source.year;
        result.Timeout = source.timeout;
        result.Version = source.version;
        result.SupportedCultures = source.supportedCultures;
        result.Features = source.features;
        result.IsSingleTenant = source.isSingleTenant;

        return result;
    }

    public static toModel(source: ServerInfoDTO): ServerInfo {
        if (source == null)
            return null;

        let result = new ServerInfo();

        result.currentCulture = source.CurrentCulture;
        result.currentLocale = source.CurrentLocale;
        result.year = source.Year;
        result.timeout = source.Timeout;
        result.version = source.Version;
        result.supportedCultures = source.SupportedCultures;
        result.features = source.Features;
        result.isSingleTenant = source.IsSingleTenant;

        return result;
    }
}